import { setTheme, setRecentSearches } from "./localstorage";

// Action to enable/disable to loading indicator
export function setLoading(isLoading) {
  return isLoading ? { type: "QT-PAGE_LOADING" } : { type: "QT-PAGE_FINISHED_LOADING" };
}

export function setToogleTheme(flag, dontUpdate = false) {
  if (!dontUpdate) {
    setTheme(flag ? "dark" : "white"); // Update theme in local storage
  }

  return {
    type: "TOGGLE_THEME",
    payload: flag,
  };
}

export function setToogleCommentWidget(payload, storyId) {
  return {
    type: "TOGGLE_COMMENTS",
    payload,
    storyId,
  };
}

export function setRecentSearch(list, dontUpdate = false) {
  if (!dontUpdate) {
    setRecentSearches(list); // Update in local storage
  }

  return {
    type: "RECENT_SEARCH",
    payload: list,
  };
}

export const setWruData = (payload, storyId) => {
  return {
    type: "STORY_WRU_DATA",
    payload,
    storyId,
  };
};

export const setWruFitData = (payload, storyId) => {
  return {
    type: "STORY_WRU_FIT_DATA",
    payload,
    storyId,
  };
};

export const setMember = (member) => {
  return {
    type: "QT-MEMBER_UPDATED",
    member,
  };
};

export const setLogoutState = () => {
  return {
    type: "SET_LOGOUT_STATE",
    payload: true,
  };
};

export const setUserPlans = (plans) => {
  return {
    type: "SET_USER_PLANS",
    plans,
  };
};

export const setSectionId = (id) => {
  return {
    type: "SECTION_ID",
    payload: id,
  };
};

export const setAdDelay = (value) => {
  return {
    type: "AD_DELAY",
    payload: value,
  };
};

export const setLoginWindow = (value, logInFrom) => {
  return {
    type: "SET_LOGIN_WINDOW",
    payload: value,
    logInFrom: logInFrom,
  };
};

export const setMakePayment = (value) => {
  return {
    type: "SET_LOGIN_UPDATE",
    payload: value,
  };
};

export const setPostLoginPayment = (value, logInFrom) => {
  return {
    type: "SET_POST_LOGIN_PAYMENT",
    payload: value,
    logInFrom: logInFrom,
  };
};

export const setPaymentSuccess = (value) => {
  return {
    type: "SET_PAYMENT_SUCCESS",
    payload: value,
  };
};

export const toggleFloatingShareMenu = (value) => {
  return {
    type: "TOGGLE_FLOATING_MENU",
    payload: value,
  };
};

export const setAudioBotLoad = (value) => {
  return {
    type: "SET_AUDIO_BOT_LOAD",
    payload: value,
  };
};
export const setAudioPlay = (value) => {
  return {
    type: "SET_AUDIO_PLAY",
    payload: value,
  };
};

export const setAudioResume = (value) => {
  return {
    type: "SET_AUDIO_RESUME",
    payload: value,
  };
};

export const setBottomStickyAd = (value) => {
  return {
    type: "SET_BOTTOM_STICKY_AD",
    payload: value,
  };
};

export const updateCampaignGroup = (value) => {
  return {
    type: "UPDATE_GROUP",
    payload: value,
  };
};

export const setLikedStory = (value) => {
  return {
    type: "LIKE_STORY",
    payload: value,
  };
};

export const setLikeCount = (value) => {
  return {
    type: "LIKE_STORY_COUNT",
    payload: value,
  };
};

export const setVoiceBotFlag = (value) => {
  return {
    type: "SET_VOICE_BOT_FLAG",
    payload: value,
  };
};

export const setVoiceBotEnable = (value) => {
  return {
    type: "SET_VOICE_BOT_ENABLE",
    payload: value,
  };
};

export const setBlackListedStory = (value) => {
  return {
    type: "SET_BLACKLISTED_STORY",
    payload: value,
  };
};

export const setTextTheme = (value) => {
  return {
    type: "SET_TEXT_THEME",
    payload: value,
  };
};
