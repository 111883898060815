import get from "lodash/get";

export function isQuiz(story) {
  const hasQuizAttribute = get(story, ["metadata", "story-attributes", "Quiz", "0"], "False") === "True";

  return story.headline.search(/quiz/i) >= 0 || hasQuizAttribute;
}
const checkArchivedSections = (slug) => {
  const archivedSections = ["gender", "law", "explainer", "my-report", "podcast", "neon", "politics", "opinion", "fit"];
  const regex = new RegExp("\\b(" + archivedSections.join("|") + ")\\b", "gi");
  return regex.test(slug);
};
export const isPaywalled = (story) => {
  const storyAccessLevel = story["access-level-value"];
  const [freemium] = isFreemium(story);
  if (storyAccessLevel === 1) return false;
  const currentDate = new Date();
  const storyPublished = story["published-at"];
  const storyAge = Math.abs(currentDate - storyPublished) / (24 * 60 * 60 * 1000);
  if (freemium) {
    if (storyAccessLevel === 24) {
      return storyAge > 1;
    }
    if (storyAccessLevel === 48) {
      return storyAge > 2;
    }
    if (storyAccessLevel === 96) {
      return storyAge > 4;
    }
  }

  if (story.access === "subscription" && !freemium) return true;

  const sectionSlug = story && story.sections && story.sections[0].slug;
  if (sectionSlug === "opinion" || sectionSlug === "elections" || sectionSlug === "election-analysis") {
    return storyAge > 2;
  }

  const sectionCheck = checkArchivedSections(story["story-template"] === "explainer" ? "explainer" : story.slug);
  if (!sectionCheck) {
    return false;
  }

  if (story.slug.includes("fit")) {
    const archivedSubSections = ["fit-webqoof", "fit-check"];
    const regexSub = new RegExp("\\b(" + archivedSubSections.join("|") + ")\\b", "gi");
    if (regexSub.test(story.slug)) {
      return false;
    }
    const ids = [1645383, 1676411, 1641157];
    if (ids.includes(story["author-id"])) return false;
  }
  const tags = story && story.tags && story.tags.filter((it) => it.slug === "electoral-bonds");
  if (tags && tags.length && storyAge > 2) {
    return true;
  }

  return story["story-template"] === "explainer" ? storyAge > 1 : storyAge > 30;
};

export const isFreemium = (story) => {
  const { "access-level-value": storyAccessLevel } = story;

  const isFreemiumLevel = [24, 48, 96].includes(storyAccessLevel);
  return [isFreemiumLevel, storyAccessLevel];
};

export function isPodcast(story) {
  return story?.slug?.split("/")[0] === "podcast";
}
